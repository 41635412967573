export default {
    data: () => ({
        id: 0,
        data: {},
        resourceUri: "",
        success: false
    }),
    created() {
        const urlParams = new URLSearchParams(location.search);
        this.id = urlParams.get("id");
        if (this.editMode) {
            this.getData();
        }
    },
    computed: {
        editMode() {
            return this.id;
        }
    },
    methods: {
        async saveAndBack() {
            if (this.editMode) {
                await this.update();
            } else {
                await this.save();
            }

            if (this.success) {
                this.$router.go(-1);
            }
            this.success = false;
        },
        async update() {
            const formData = new FormData();
            Object.keys(this.data).forEach(key => {
                if (this.data[key]) {
                    formData.append(key, this.data[key]);
                }
            });
            formData.append("_method", "PATCH");
            try {
                await this.axios.post(
                    `${this.resourceUri}/${this.id}`,
                    formData,
                    {
                        headers: {
                            "Content-Type": `multipart/form-data;`
                        }
                    }
                );
                this.success = true;
            } catch (e) {
                console.log(e);
            }
        },
        async save() {
            const formData = new FormData();
            Object.keys(this.data).forEach(key => {
                if (this.data[key]) {
                    formData.append(key, this.data[key]);
                }
            });
            try {
                await this.axios.post(this.resourceUri, formData, {
                    headers: {
                        "Content-Type": `multipart/form-data;`
                    }
                });
                this.data = {};
                this.success = true;
            } catch (e) {
                console.log(e);
            }
        },
        async getData() {
            const response = await this.axios.get(
                `${this.resourceUri}/${this.id}`
            );
            this.data = response.data;
        }
    }
};
