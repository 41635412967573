export default {
    data: () => ({
        errors: {}
    }),
    mounted() {
        this.$eventBus.$on("display-errors", this.getErrors);
    },
    methods: {
        getErrors(errors) {
            this.errors = errors;
        }
    }
};
